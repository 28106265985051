// HomePage.js
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PDF from 'react-pdf-js';
import './HomePage.css';

function HomePage() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleFileUpload = async (file) => {
    if (file.type !== 'application/pdf') {
      alert('Please upload a PDF file.');
      return;
    }
    const formData = new FormData();
    formData.append('pdfFile', file);
    setFileName(file.name);

    try {
      await axios.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setFile(URL.createObjectURL(file));
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.type === 'application/pdf') {
      handleFileUpload(uploadedFile);
    } else {
      alert('Please upload a PDF file.');
    }
  };
  
  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type === 'application/pdf') {
      handleFileUpload(file);
    } else {
      alert('Please upload a PDF file.');
    }
  }, []);
  

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleNextStep = () => {
    navigate('/JobDesc', { state: { fileName } });
  };

  return (
    <div className="App">
      <div className="banner">
        <h1>ResuFit AI</h1>
      </div>
      <div className="container">
        <div className="upload-section" onDrop={handleDrop} onDragOver={handleDragOver} onClick={() => document.getElementById('fileInput').click()}>
          {file ? (
            <PDF file={file} />
          ) : (
            <label htmlFor="fileInput" className="upload-label">
              {fileName ? `Uploaded: ${fileName}` : "Drag and drop a file, or click to select one."}
            </label>
          )}
          <input
            type="file"
            onChange={handleFileChange}
            accept="application/pdf"
            style={{ display: 'none' }}
            id="fileInput"
          />
        </div>
        {file && (
          <button className="next-step-button" onClick={handleNextStep} disabled={!file}>
            Next Step →
          </button>
        )}
      </div>
    </div>
  );
}
export default HomePage;
