import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './JobDesc.css';
import chipicat from './chipichipi_cat.gif'

function wait(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

function JobDescription() {
    const [input, setInput] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { fileName } = location.state || {};

    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      let jobDescription = input;
      wait(5000);
      if (input.startsWith('http')) {
          setStatus('URL: web scraping...');
          console.log('URL detected:', input);
          try {
            const response = await axios.post('/api/scrape', { url: input }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setStatus('Scraping complete');
            if (response.data.success) {
              console.log("Scraping complete:", response.data.desc);
              setStatus('Scraping complete');
              jobDescription = response.data.desc;
              // Assuming jobDescription is a variable you have previously declared
              // You can now use jobDescription as needed, for example, display it on the UI
          } else {
              console.log("Scraping failed with message:", response.data.message);
              setStatus('Scraping failed');
          }
        } catch (error) {
            console.error('Error scraping URL:', error);
            setStatus('Failed to scrape URL');
        }
      }
  
      setStatus('Creating Resume');
      try {
          const api_response = await axios.post('/api/resume_editor', {
              fileName: fileName,
              jobDescription: jobDescription
          });
            if (api_response.data.success === true){
              setStatus('Check out the Resume');
              await wait(3000);
              /*
              try {
                const response = await axios.get(`http://localhost:5000/download/${fileName}`, {
                  responseType: 'blob',
                });
                console.log('Received Blob type:', response.data.type);
                console.log('Received Blob size:', response.data.size);
              
                const url = URL.createObjectURL(response.data);
                console.log('Object URL:', url);  // Log to see the URL
              
                // Creating a temporary link element to download the file
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
              
                // Cleanup: revoke the object URL after download
                URL.revokeObjectURL(url);
                document.body.removeChild(link);
              } catch (error) {
                console.error('Error downloading file:', error);
              } */
              navigate('/result', { state: { fileName } });
            }
            else{
              setStatus('Something went wrong, try again');
            }
      } catch (error) {
          console.error(error);
      }
      setLoading(false);
  };
  

    return (
      <div className="job-description-container">
        <h1>Submit Job Description</h1>
        <form onSubmit={handleSubmit} className="job-description-form">
          <textarea
            className="job-description-textarea"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Paste the job description or enter the job posting URL here"
          ></textarea>
          <button type="submit" className="submit-button">Submit</button>
        </form>
        <div className="status-container">
          <p>{status}</p>
        </div>
        {loading && <img src={chipicat} alt="Loading..." className="loading-gif" />}
      </div>
    );

  }
  
  export default JobDescription;