// HomePage.js
import React, { useState, useEffect } from "react";
import axios from "axios";

const TestPage = () => {
  const [serverMessage, setServerMessage] = useState("");

  // Function to ping the backend API
  const pingServer = async () => {
    try {
      const response = await axios.get("/api/ping"); // Send request to /api/ping
      setServerMessage(response.data); // Update the state with the response message
    } catch (error) {
      console.error("Error pinging the server", error);
      setServerMessage("Error connecting to server");
    }
  };

  useEffect(() => {
    pingServer(); // Call pingServer when the component mounts
  }, []);

  return (
    <div>
      <h1>Ping the Server</h1>
      <p>{serverMessage ? serverMessage : "Pinging server..."}</p>
    </div>
  );
};

export default TestPage;
